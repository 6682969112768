import { gql } from '@apollo/client';

//==============================================================
// START Query
//==============================================================

export const GET_STORE_MENUS = gql`
  query getStoreMenus($storeEngName: String!) {
    getStoreMenus(storeEngName: $storeEngName) {
      id
      menuName
      price
      imageLocation
      eatenCount
      viewCount
      store {
        logoImageOriginalLocation
      }
    }
  }
`;

export const GET_MENU = gql`
  query getMenu($id: Int!) {
    getMenu(id: $id) {
      id
      menuName
      price
      description
      imageLocation
      imageKey
      eatenCount
      viewCount
      store {
        id
        storeName
        storeEngName
        logoImageOriginalKey
        logoImageOriginalLocation
      }
      components {
        id
        name
        price
        storeType
        componentType
        imageLocation
        imageKey
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MENUS_ID = gql`
  query getMenus {
    getMenus {
      id
      store {
        storeEngName
      }
    }
  }
`;

export const GET_MENUS_ADMIN = gql`
  query getMenusAdmin {
    getMenusAdmin {
      id
      menuName
      price
      description
      imageLocation
      imageKey
      eatenCount
      viewCount
      store {
        storeName
        storeEngName
      }
      components {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPONENTS = gql`
  query getComponents {
    getComponents {
      id
      name
      price
      storeType
      componentType
      imageLocation
      imageKey
      usedMenus {
        menuName
        price
        description
        eatenCount
        viewCount
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPONENT = gql`
  query getComponent($id: Int!) {
    getComponent(id: $id) {
      id
      name
      price
      storeType
      componentType
      imageLocation
      imageKey
      usedMenus {
        menuName
        price
        description
        eatenCount
        viewCount
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_STORES = gql`
  query getStores {
    getStores {
      id
      storeName
      storeEngName
      logoImageOriginalKey
      logoImageOriginalLocation
      menus {
        id
        menuName
        price
        imageLocation
        eatenCount
        viewCount
      }
    }
  }
`;

export const GET_STORE = gql`
  query getStore($id: Int!) {
    getStore(id: $id) {
      id
      storeName
      storeEngName
      logoImageOriginalKey
      logoImageOriginalLocation
      menus {
        id
        menuName
      }
    }
  }
`;

export const GET_STORE_BY_NAME = gql`
  query getStoreByName($storeEngName: String!) {
    getStoreByName(storeEngName: $storeEngName) {
      id
      storeName
      storeEngName
      logoImageOriginalKey
      logoImageOriginalLocation
    }
  }
`;

//==============================================================
// END Query
//==============================================================

//==============================================================
// START Muatations
//==============================================================

export const CREATE_ADMIN = gql`
  mutation createAdmin($username: String!, $password: String!) {
    createAdmin(createAdminInput: { username: $username, password: $password }) {
      id
      username
      createdAt
      updatedAt
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(loginInput: { username: $username, password: $password })
  }
`;

export const CREATE_MENU = gql`
  mutation createMenu(
    $file: Upload
    $menuName: String!
    $price: Int!
    $storeType: StoreType!
    $description: String
    $componentIds: [Int!]!
  ) {
    createMenu(
      file: $file
      createMenuInput: {
        menuName: $menuName
        price: $price
        storeType: $storeType
        description: $description
        componentIds: $componentIds
      }
    ) {
      id
      menuName
    }
  }
`;

export const REMOVE_MENU = gql`
  mutation removeMenu($id: Int!) {
    removeMenu(id: $id)
  }
`;

export const EAT_MENU = gql`
  mutation eatMenu($id: Int!) {
    eatMenu(id: $id)
  }
`;

export const VIEW_MENU = gql`
  mutation viewMenu($id: Int!) {
    viewMenu(id: $id)
  }
`;

export const CREATE_COMPONENT = gql`
  mutation createComponent(
    $file: Upload!
    $name: String!
    $price: Int!
    $storeType: StoreType!
    $componentType: ComponentType!
  ) {
    createComponent(
      file: $file
      createComponentInput: {
        name: $name
        price: $price
        storeType: $storeType
        componentType: $componentType
      }
    ) {
      id
      name
      price
    }
  }
`;

export const UPDATE_COMPONENT = gql`
  mutation updateComponent(
    $componentId: Int!
    $file: Upload
    $name: String
    $price: Int
    $storeType: StoreType
    $componentType: ComponentType
  ) {
    updateComponent(
      file: $file
      updateComponentInput: {
        id: $componentId
        name: $name
        price: $price
        storeType: $storeType
        componentType: $componentType
      }
    ) {
      id
      name
      price
    }
  }
`;

export const REMOVE_COMPONENT = gql`
  mutation removeComponent($id: Int!) {
    removeComponent(id: $id)
  }
`;

export const CREATE_STORE = gql`
  mutation createStore($file: Upload!, $storeName: String!, $storeEngName: String!) {
    createStore(
      file: $file
      createStoreInput: { storeName: $storeName, storeEngName: $storeEngName }
    ) {
      id
      storeName
      storeEngName
      logoImageOriginalLocation
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore($storeId: Int!, $file: Upload, $storeName: String, $storeEngName: String) {
    updateStore(
      file: $file
      updateStoreInput: { id: $storeId, storeName: $storeName, storeEngName: $storeEngName }
    ) {
      id
      storeName
      storeEngName
      logoImageOriginalLocation
    }
  }
`;

//==============================================================
// END Muatations
//==============================================================
