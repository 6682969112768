import { EyeIcon, ThumbUpIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { addComma } from '../../../utils/functions';

interface MenuCardWideProps {
  menuId: number;
  menuName: string;
  price: string;
  eatenCount: string;
  viewCount: string;
  imageUrl: string;
  storeEngName: string;
}

const MenuCardWide: React.FC<MenuCardWideProps> = ({
  eatenCount,
  imageUrl,
  menuId,
  menuName,
  price,
  storeEngName,
  viewCount,
}) => {
  const router = useRouter();
  const onClick = () => {
    router.push(`/${storeEngName}/${menuId}`);
  };

  return (
    <button onClick={onClick} className="flex w-full h-44">
      <div className={`relative w-1/3 h-full ${!imageUrl && 'opacity-30'}`}>
        <Image
          src={imageUrl || '/assets/images/image-not-found.png'}
          alt="menu image"
          layout="fill"
          objectFit="cover"
          priority={false}
        />
      </div>
      <div className="text-left h-full flex flex-col justify-between w-2/3 pl-4 pr-2">
        <div>
          <div className="text-xs text-gray-300 font-medium">{storeEngName.toUpperCase()}</div>
          <div className="font-medium text-lg text-left break-all line-clamp-1 pb-1">
            {menuName}
          </div>
          <div className="text-red-400">{`₩ ${addComma(price)}`}</div>
        </div>
        <div className="flex justify-between text-xs">
          <div className="flex space-x-1 items-center">
            <ThumbUpIcon width={15} height={15} className="text-gray-500" />
            <span>먹어봤어요! {eatenCount}회</span>
          </div>
          <div className="flex space-x-1 items-center">
            <EyeIcon width={15} height={15} className="text-gray-500" />
            <span>{viewCount}</span>
          </div>
        </div>
      </div>
    </button>
  );
};

export default MenuCardWide;
