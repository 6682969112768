import { EyeIcon, ThumbUpIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { addComma } from '../../../utils/functions';

interface MenuCardSquareProps {
  menuId: number;
  storeEngName: string;
  menuName: string;
  price: string;
  eatenCount: string;
  viewCount: string;
  imageUrl: string;
}

export const MenuCardSquare: React.FC<MenuCardSquareProps> = ({
  menuId,
  storeEngName,
  menuName,
  price,
  eatenCount,
  viewCount,
  imageUrl,
}) => {
  const router = useRouter();
  const onClick = () => {
    router.push(`/${storeEngName}/${menuId}`);
  };

  return (
    <button
      onClick={onClick}
      className="flex flex-col items-center w-52 h-52 space-y-2 text-sm hover:opacity-70"
    >
      <div className={`relative w-full h-1/2 ${!imageUrl && 'opacity-30'}`}>
        <Image
          src={imageUrl || '/assets/images/image-not-found.png'}
          alt="menu image"
          layout="fill"
          objectFit="contain"
          priority={false}
        />
      </div>
      <div className="font-medium break-all line-clamp-1">{menuName}</div>
      <div className="transform -translate-y-1 text-red-400">{`₩ ${addComma(price)}`}</div>
      <div className="flex w-full justify-around text-xs">
        <div className="flex items-center space-x-1">
          <ThumbUpIcon width={12} height={12} className="text-gray-500" />
          <span>{eatenCount}</span>
        </div>
        <div className="flex items-center space-x-1">
          <EyeIcon width={12} height={12} className="text-gray-500" />
          <span>{viewCount}</span>
        </div>
      </div>
    </button>
  );
};

export default MenuCardSquare;
