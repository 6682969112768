import Head from 'next/head';

interface HeaderProps {
  title?: string;
  favIcon?: string;
  description?: string;
  keywords?: string;
  url?: string;
  ogImage?: string;
  ogTitle?: string;
}

const Header: React.FC<HeaderProps> = ({
  title = '커스텀메뉴',
  favIcon = 'https://custommenu.cdn.swygbro.com/assets/logo/logo.png',
  description = '커스텀 메뉴 사이트에서 다양한 매장의 꿀조합을 찾고 만들어보세요.',
  keywords = '스타벅스,서브웨이,커스텀,메뉴,커스텀메뉴,custom,menu,음료,조합,레시피',
  url = 'https://custommenu.swygbro.com',
  ogImage = 'https://custommenu.cdn.swygbro.com/assets/logo/logo.png',
  ogTitle,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="X_UA_Compatible" content="IE-edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
      <link rel="icon" href={favIcon} />

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="커스텀메뉴" />
      <meta property="og:type" content="website" />

      <meta property="twitter:title" content={ogTitle || title} />
      <meta property="twitter:image" content={ogImage} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:site_name" content="커스텀메뉴" />
    </Head>
  );
};

export default Header;
