import { useRouter } from 'next/router';

export const NavBar = () => {
  const router = useRouter();

  const onLogoClick = () => {
    router.push('/');
  };

  return (
    <nav className="w-full flex justify-center px-8 select-none border-b">
      <button
        className="py-4 text-2xl font-logo text-cmGreen-400 hover:opacity-60 active:opacity-100"
        onClick={onLogoClick}
      >
        Custom Menu
      </button>
    </nav>
  );
};

export default NavBar;
